import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import BG from '../images/awards-bg.jpg'

const NavLink = props => {
	if (!props.test) {
		return <Link to={props.url}>{props.text}</Link>
	} else {
		return <span>{props.text}</span>
	}
}
class Blog extends React.Component {
	render() {
		const { data } = this.props
		const siteTitle = data.site.siteMetadata.title
		const { group, index, first, last } = this.props.pageContext
		const previousUrl = index - 1 === 1 ? '/' : (index - 1).toString()
		const nextUrl = (index + 1).toString()
		// console.log(this)
		// console.log(this.props)

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO
					title="All posts"
					keywords={[`blog`, `gatsby`, `javascript`, `react`]}
				/>
				<div
					id="sliderhead"
					className="blog-header blogheadpost"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
				>
					<div className="container">
						<div className="text-det">
							<h5 className="related-title">Latest Blogs</h5>
						</div>
					</div>
				</div>
				<div className="list-allblog" style={{ background: '#f5f5f5' }}>
					<div className="container pt-5">
						<div className="col-md-12">
							<div className="list-blog-all">
								{group.map(blog => (
									<div className="blog-items" key={blog.node.id}>
										<div className="blog-det">
											<h3 className="blog-title">
												<Link to={blog.node.slug.current}>
													{blog.node.title}
												</Link>
											</h3>
											<div className="short-desc">
												<p>{blog.node.excerpt}</p>
											</div>
											<div className="row">
												<div className="col-md-4 col-sm-4 col-xs-4 morebtnblog">
													<p className="read-more">
														<Link to={blog.node.slug.current}>
															Read More
															<i
																ariaHidden="true"
																className="fa fa-long-arrow-right"
															/>
														</Link>
													</p>
												</div>
												<div className="col-md-8 col-sm-8 col-xs-8 published text-right">
													<p className="info">
														<i className="fa fa-bookmark-o" /> <i>By</i>{' '}
														<b>{blog.node.author.name}</b> |{' '}
														<i className="fa fa-clock-o" /> Created on{' '}
														<b> {blog.node.publishedAt}</b>
													</p>
												</div>
											</div>
										</div>
									</div>
								))}
								<nav>
									<ul className="pager">
										<li className="previous disabled  d-inline">
											<NavLink
												test={first}
												url={'blog/' + previousUrl}
												text="← Prev"
											/>
										</li>
										<li className="next float-right d-inline">
											<NavLink
												test={last}
												url={'blog/' + nextUrl}
												text="Next →"
											/>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}
export default Blog

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`
